import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import "../styles/style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
// You can delete this file if you're not using it
const TeamPage = (data) => (
  <Layout pageInfo={{ pageName: "team" }}>
    <SEO title="Team" keywords={[]} />
    <Container fluid={true} className="team">
      <h1>Meet The Team</h1>
      <Row>
        <Col lg={3} className="avatar">
          <div className="portrait">
            <Img fixed={data.data.gregory.childImageSharp.fixed} alt="Gregory Fischbach" fadeIn={false}/>
          </div>
          <div className="information">
            <h3>Greg Fischbach</h3>
            <h4>Strategy, PR and Legal</h4>
            <p>
              Chairman of the Board at Rabbit<br/>
              Founder &amp; CEO of Acclaim Entertainment<br/>
              President at RCA Records International<br/>
              President at Activision International<br/>
              JD from Hastings College of the Law<br/>
            </p>
          </div>
        </Col>
        <Col lg={3} className="avatar">
          <div className="portrait">
            <Img fixed={data.data.philippe.childImageSharp.fixed} alt="Philippe Clavel"  fadeIn={false}/>
          </div>
          <div className="information">
            <h3>Philippe Clavel</h3>
            <h4>Operations, Engineering and Gaming</h4>
            <p>
              CTO at Rabbit<br/>
              CTO at YooStar Entertainment<br/>
              Technical Director at Sony Online Entmt<br/>
              Director of Engineering at Hands-On Mobile<br/>
              MS Engineering from Sup&eacute;lec<br/>
            </p>
          </div>
        </Col>
        <Col lg={3} className="avatar">
          <div className="portrait">
            <Img fixed={data.data.john.childImageSharp.fixed} alt="John Chao" fadeIn={false}/>
          </div>
          <div className="information">
            <h3>John Chao</h3>
            <h4>Marketing, Data and Finance</h4>
            <p>
              Chief Data &amp; Strategy Officer at Rabbit<br/>
              VP of Marketing at Teleflora<br/>
              CEO of Takira Interactive<br/>
              Senior Consultant at Bain &amp; Company<br/>
              MBA from Harvard Business School
            </p>
          </div>
        </Col>
        <Col lg={3} className="avatar">
          <div className="portrait">
            <Img fixed={data.data.brett.childImageSharp.fixed} alt="Brett Gow" fadeIn={false}/>
          </div>
          <div className="information">
            <h3>Brett Gow</h3>
            <h4>Production, BD and Gaming</h4>
            <p>
              Studio Partner &amp; GM at Signal Studios<br/>
              Development Director at Glu Mobile<br/>
              Sr. Producer at Microsoft Games Studio<br/>
              Executive Producer at Nintendo<br/>
              VP Product at Acclaim Entertainment<br/>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default TeamPage;

export const query = graphql`
  query {
    gregory: file(relativePath: { eq: "gregory.png" }) {
      childImageSharp {
        fixed(width: 246, height: 246) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    philippe: file(relativePath: { eq: "philippe.png" }) {
      childImageSharp {
        fixed(width: 246, height: 246) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    john: file(relativePath: { eq: "john.png" }) {
      childImageSharp {
        fixed(width: 246, height: 246) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    brett: file(relativePath: { eq: "brett.png" }) {
      childImageSharp {
        fixed(width: 246, height: 246) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
